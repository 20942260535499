import store from '../../../../src/store/store.js'
const { permissions, username } = store.state.AppActiveUser
const show_menu = []

const dashboard = {
  url: null,
  name: 'สรุปยอด',
  slug: '',
  icon: 'HomeIcon',
  submenu: [
    {
      url: '/',
      name: 'สรุปยอดรายวัน',
      slug: 'dashboard'
    },
    {
      url: '/month',
      name: 'สรุปยอดเดือน',
      slug: 'month'
    }
  ]
}

const user = {
  url: '/member',
  name: 'ข้อมูลยูสเซอร์',
  slug: 'member',
  icon: 'UsersIcon',
  submenu: [
    {
      url: '/membersearch',
      name: 'ค้นหาข้อมูลยูสเซอร์',
      slug: 'membersearch'
    }
  ]
}

const addcredit = {
  url: '/agent',
  name: 'เติม-ถอน เครดิต',
  slug: 'agent',
  icon: 'CreditCardIcon'
}

const minuscredit = {
  url: '/agentminus',
  name: 'ถอน เครดิต',
  slug: 'agentminus',
  icon: 'CreditCardIcon'
}

const topup = {
  url: '/topup',
  name: 'รายการเพิ่มเครดิต',
  slug: 'topup',
  icon: 'PlusCircleIcon'
}

const makeWithdraw = {
  url: '/makeWithdraw',
  name: 'เมคยอดถอน (PGM)',
  slug: 'makeWithdraw',
  icon: 'MinusCircleIcon'
}

const withdraw = {
  url: null,
  name: 'รายการถอนเงิน',
  slug: '',
  icon: 'MinusCircleIcon',
  submenu: [
    {
      url: '/withdraw',
      name: 'รอทำรายการ',
      slug: 'withdraw'
    },
    {
      url: '/withdrawwait/pendingUFA',
      name: 'รอตัดเครดิต UFA',
      slug: 'withdrawpendingufa'
    },
    {
      url: '/withdrawwait/error',
      name: 'ถอนไม่สำเร็จ',
      slug: 'withdrawerror'
    },
    {
      url: '/withdrawlist',
      name: 'ประวัติทำรายการ',
      slug: 'withdrawlist'
    },
    {
      url: '/withdrawwait/pending',
      name: 'รอถอน Payment',
      slug: 'withdrawpending'
    }
  ]
}

const truewallet = {
  url: '/wallet',
  name: 'รายการ Truewallet',
  slug: 'wallet',
  icon: 'ListIcon'
}

const bank = {
  url: '/bank',
  name: 'รายการ ธนาคาร',
  slug: 'bank',
  icon: 'DollarSignIcon'
}

const payment = {
  url: null,
  name: 'รายการ Payment',
  slug: 'payment',
  icon: 'DollarSignIcon',
  submenu: [
    {
      url: '/payment',
      name: 'รายการฝาก Vizpay',
      slug: 'payment'
    },
    {
      url: '/payment-trans',
      name: 'Vizpay Dashboard',
      slug: 'payment-dashboard'
    },
    {
      url: '/payment-topup',
      name: 'เติมเงิน Vizpay',
      slug: 'payment-topup'
    },
    {
      url: '/payment-transfer',
      name: 'โยกเงินฝาก Vizpay',
      slug: 'payment-transfer'
    },
    /*   {
      url: '/hengpay',
      name: 'รายการฝาก Hengpay',
      slug: 'hengpay',
      icon: 'DollarSignIcon'
    },
    {
      url: '/omegapay',
      name: 'รายการฝาก Omega',
      slug: 'omegapay',
      icon: 'DollarSignIcon'
    }, */
    {
      url: '/pompay',
      name: 'รายการฝาก Pompay',
      slug: 'pompay',
      icon: 'DollarSignIcon'
    },
    {
      url: '/pompay-generate',
      name: 'สร้าง QR Pompay',
      slug: 'pompay-generate',
      icon: 'DollarSignIcon'
    },
    {
      url: '/pompay-ckeck-slip',
      name: 'ตรวจสอบ Slip Pompay',
      slug: 'pompay-ckeck-slip',
      icon: 'DollarSignIcon'
    },
    {
      url: '/fpayment',
      name: 'รายการฝาก Fpayment',
      slug: 'fpayment'
    },
    {
      url: '/fpayment-dashboard',
      name: 'ข้อมูล Fpayment',
      slug: 'fpayment-dashboard'
    },
    {
      url: '/7p',
      name: 'รายการฝาก 7P',
      slug: '7p'
    },
    {
      url: '/akpay',
      name: 'รายการฝาก AK',
      slug: 'akpay',
      icon: 'DollarSignIcon'
    },
    {
      url: '/akpay-generate',
      name: 'สร้างรายการ AK',
      slug: 'akpay-generate'
    }
  ]
}

const pay2coin = {
  url: null,
  name: 'รายการฝาก Pay2Coin',
  slug: 'pay2coin',
  icon: 'DollarSignIcon',
  submenu: [
    {
      url: '/pay2coin',
      name: 'รายการฝาก Pay2coin',
      slug: 'pay2coin'
    },
    {
      url: '/pay2coin-dashboard',
      name: 'ระบบหลังบ้าน Pay2coin',
      target: '_blank',
      slug: 'pay2coin'
    }
  ]
}

const callcenter = {
  url: null,
  name: 'รายการ Callcenter',
  slug: 'callcenter',
  icon: 'PhoneIcon',
  submenu: [
    {
      url: '/callcenter-list',
      name: 'รายการ Callcenter',
      slug: 'callcenter-list'
    },
    {
      url: '/callcenter-confirm',
      name: 'รายการ Confirm',
      slug: 'callcenter'
    },
    {
      url: '/callcenter-setting',
      name: 'รายการ setting',
      slug: 'callcenter-setting'
    }
  ]
}

const settingBankShow = {
  url: '/SettingBank',
  name: 'SettingBankShow',
  slug: 'SettingBank',
  icon: 'SettingsIcon'
}

const setting = {
  url: null,
  name: 'ตั้งค่าระบบ',
  slug: '',
  icon: 'SettingsIcon',
  submenu: [
    {
      url: '/SettingSystem',
      name: 'ระบบ หน้าเว็บ',
      slug: 'settingsystem'
    },
    {
      url: '/SettingWithdraw',
      name: 'ระบบ ถอนเงิน',
      slug: 'settingwithdraw'
    },
    {
      url: '/SettingPopup',
      name: 'ระบบ แจ้งเตือน',
      slug: 'popupsetting'
    },
    {
      url: '/SettingRanking',
      name: 'ระบบ Ranking',
      slug: 'SettingRanking'
    },
    {
      url: '/SettingMvp',
      name: 'ระบบ MVP',
      slug: 'SettingMvp'
    },
    {
      url: '/SettingRateCrypto',
      name: 'ระบบ Crypto Rate',
      slug: 'settingRateCrypto'
    },
    {
      url: null,
      name: 'ระบบ บัญชี',
      slug: 'Acc',
      submenu: [
        {
          url: '/SettingWalletDeposit',
          name: 'บัญชีฝาก',
          slug: 'settingwalletdeposit'
        },
        {
          url: '/SettingWalletWithdraw',
          name: 'บัญชีถอน',
          slug: 'settingwalletwithdraw'
        },
        {
          url: '/SettingTruewallet',
          name: 'บัญชี Truewallet',
          slug: 'settingtruewallet'
        }
      ]
    }
  ]
}

const report = {
  url: null,
  name: 'รีพอร์ท',
  slug: 'report',
  icon: 'DownloadIcon',
  submenu: [
    {
      url: '/reportlogusage',
      name: 'ประวัติการใช้งาน',
      slug: 'reportlogusage'
    },
    {
      url: '/reportpromotion',
      name: 'โบนัส',
      slug: 'reportpromotion'
    },
    {
      url: '/reportaff',
      name: 'แนะนำเพื่อน',
      slug: 'reportaff'
    },
    {
      url: '/reportturn',
      name: 'รับเงินคืน',
      slug: 'reportturn'
    },
    {
      url: '/reportregister',
      name: 'สมัคร / เติมเงิน',
      slug: 'reportregister'
    },
    {
      url: '/reportminigame',
      name: 'เปิดไพ่ / กงล้อ',
      slug: 'reportminigame'
    },
    {
      url: '/reportactiveuser',
      name: 'Activeuser',
      slug: 'reportactiveuser'
    },
    {
      url: '/ReportTournamentPnl',
      name: 'เกาจิ้งเมืองไทย',
      slug: 'Tournament'
    }

  ]
}

const admin = {
  url: '/settingstaff',
  name: 'ระบบ พนักงาน',
  slug: 'settingstaff',
  icon: 'SettingsIcon'
}

const creditfree = {
  url: null,
  name: 'รายการเครดิตฟรี',
  slug: '',
  icon: 'CreditCardIcon',
  submenu: [
    {
      url: '/creditfreewait',
      name: 'รอทำรายการ',
      slug: 'creditfreewait'
    },
    {
      url: '/creditfreelist',
      name: 'รายการเครดิตฟรี',
      slug: 'creditfreelist'
    },
    {
      url: '/creditfreeinfo',
      name: 'แก้ไขลิงค์',
      slug: 'creditfreeinfo'
    }
  ]
}

const review = {
  url: null,
  name: 'เพิ่ม-ลด รูปภาพ',
  slug: '',
  icon: 'ListIcon',
  submenu: [
    {
      url: '/review',
      name: 'คำชม',
      slug: 'review'
    },
    {
      url: '/jackpot',
      name: 'Jackpot',
      slug: 'jackpot'
    }

  ]
}

const audit = {
  url: null,
  name: 'ตรวจสอบบัญชีถอน',
  slug: '',
  icon: 'CreditCardIcon',
  submenu: [
    {
      url: '/checkbankwithdraw/account',
      name: 'รายการบัญชีถอน',
      slug: 'checkbankwithdraw/account'
    },
    {
      url: '/checkbankwithdraw/adjustbalance',
      name: 'เติม เครดิต',
      slug: 'checkbankwithdraw/adjustbalance'
    },
    {
      url: '/checkbankwithdraw/adjustwithdraw',
      name: 'เบิก เครดิต',
      slug: 'checkbankwithdraw/account'
    },
    {
      url: '/checkbankwithdraw/upslip',
      name: 'อัพสลิป',
      slug: 'checkbankwithdraw/upslip'
    }
  ]
}

const temporary = {
  url: null,
  name: 'ตั้งค่าบัญชีพัก',
  slug: '',
  icon: 'SettingsIcon',
  submenu: [
    {
      url: '/SettingTemporary',
      name: 'รายการบัญชีพัก',
      slug: 'SettingTemporary'
    }
  ]
}

const agent = {
  url: null,
  name: 'Agent ตัวเกม',
  slug: '',
  icon: 'HomeIcon',
  submenu: [
    {
      url: 'https://ag.ufabet.com',
      target: '_blank',
      name: 'ag.ufabet.com',
      slug: 'external',
      icon: 'HomeIcon'
    },
    {
      url: 'https://ag.ufabet.net',
      target: '_blank',
      name: 'ag.ufabet.net',
      slug: 'external',
      icon: 'HomeIcon'
    },
    {
      url: 'https://ag.betufa.com',
      target: '_blank',
      name: 'ag.betufa.com',
      slug: 'external',
      icon: 'HomeIcon'
    },
    {
      url: 'https://ag.ufabet1688.com',
      target: '_blank',
      name: 'ag.ufabet1688.com',
      slug: 'external',
      icon: 'HomeIcon'
    },
    {
      url: 'https://ag.ufa6666.com',
      target: '_blank',
      name: 'ag.ufa6666.com',
      slug: 'external',
      icon: 'HomeIcon'
    },
    {
      url: 'https://ag.ufa7777.com',
      target: '_blank',
      name: 'ag.ufa7777.com',
      slug: 'external',
      icon: 'HomeIcon'
    },
    {
      url: 'https://ag.ufa9999.com',
      target: '_blank',
      name: 'ag.ufa9999.com',
      slug: 'external',
      icon: 'HomeIcon'
    }
  ]
}

const prediction = {
  url: '',
  name: 'รายการทายผลฟุตบอลโลก',
  slug: '',
  icon: 'HomeIcon',
  submenu: [
    {
      url: '/prediction/list',
      name: 'รายชื่อผู้ทายผล',
      slug: ''
    },
    {
      url: '/prediction/img',
      name: 'รายชื่อส่งรูป',
      slug: ''
    }
  ]
}


const accountManagement = {
  url: null,
  name: 'จัดการบัญชีภายใน',
  slug: '',
  icon: 'CreditCardIcon',
  submenu: [
    {
      url: '/internal-transfer/manual',
      name: 'โยกเงินบัญชีภายใน',
      slug: 'internal-transfer/manual'
    },
    {
      url: '/internal-transfer/logs',
      name: 'ประวัติการโยกเงิน',
      slug: '/internal-transfer/logs'
    }
  ]
}

// Check permission and add menus
if (permissions) {
  if (permissions.dashboard && permissions.dashboard.read) show_menu.push(dashboard)
  if (username === 'pgmkub') show_menu.push(makeWithdraw)
  if (permissions.user && permissions.user.read) show_menu.push(user)
  if (permissions.addcredit && permissions.addcredit.action) show_menu.push(addcredit) // Action only
  if (permissions.minuscredit && permissions.minuscredit.action) show_menu.push(minuscredit) // Action only
  if (permissions.topup && permissions.topup.read) show_menu.push(topup)
  if (permissions.withdraw && permissions.withdraw.read) show_menu.push(withdraw)
  if (permissions.truewallet && permissions.truewallet.read) show_menu.push(truewallet)
  if (permissions.payment && permissions.payment.read) show_menu.push(payment)
  if (permissions.payment && permissions.payment.read) show_menu.push(pay2coin)
  if (permissions.callcenter && permissions.callcenter.read) show_menu.push(callcenter)
  if (permissions.bank && permissions.bank.read) show_menu.push(bank)
  if (permissions.settingBankShow && permissions.settingBankShow.read) show_menu.push(settingBankShow)
  if (permissions.setting && permissions.setting.read) show_menu.push(setting)
  if (permissions.report && permissions.report.read) show_menu.push(report)
  if (permissions.admin && permissions.admin.read) show_menu.push(admin)
  if (permissions.creditfree && permissions.creditfree.read) show_menu.push(creditfree)
  if (permissions.review && permissions.review.read) show_menu.push(review)
  if (permissions.audit && permissions.audit.read) show_menu.push(audit)
  if (permissions.temporary && permissions.temporary.read) show_menu.push(temporary)
  if (permissions.accountManagement && permissions.accountManagement.read) show_menu.push(accountManagement)
}

// Add
show_menu.push(agent)
show_menu.push(prediction)
export default show_menu
